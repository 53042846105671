/*
 *  Utilities: Weights
 *  ---
 *  Utility classes for font weights
 */

// Settings
$weights: (
    light : $light,
    normal : $normal,
    semibold : $semibold,
    bold : $bold,
    extrabold : $extrabold,
    black: $black
) !default;

// Loop through the weights map and generate classes (e.g: u-bold).
@each $weight, $value in $weights {
  .u-#{$weight} {
    font-weight: $value !important;
  }
}
