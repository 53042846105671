/*
 *  Utilities: Spacings
 *  ---
 *  Utility classes to put specific spacing values onto elements. The below loop
 *  will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 */

$spacing-directions: (
    null,
    '-top',
    '-right',
    '-bottom',
    '-left',
);

$spacing-properties: (
    'padding',
    'margin'
);

$spacing-sizes: (
    null: $global-spacing-unit,
    '-tiny': $global-spacing-unit-tiny,
    '-small': $global-spacing-unit-small,
    '-medium': $global-spacing-unit-medium,
    '-large': $global-spacing-unit-large,
    '-huge': $global-spacing-unit-huge,
    '-x-huge': double($global-spacing-unit-huge),
    '-none': 0
) !default;

@each $property in $spacing-properties {
  @each $direction in $spacing-directions {
    @each $size, $value in $spacing-sizes {
      .u-#{$property}#{$direction}#{$size} {
        #{$property}#{$direction}: $value !important;
      }
    }
  }
}


@each $property in $spacing-properties {
  @each $direction in $spacing-directions {
    @each $size, $value in $spacing-sizes {
      .u-#{$property}#{$direction}#{$size}--negative {
        #{$property}#{$direction}: -$value !important;
      }
    }
  }
}
