/*
 *  Project Settings
 *  ---
 *  Only override the !default variables from '_constants.default.scss' to suit your project needs
 */

$global-font-size: 32px;
$global-line-height: 30px;

/**
 * Font families
 */
$primary-font-family: 'Rubik',
$fallback-font-family;
$secondary-font-family: $primary-font-family;

/**
 * Font sizes
 */
$font-size-h1: 73.24px;
$font-size-h2: 58.59px;
$font-size-h3: 46.88px;
$font-size-h4: 37.50px;
$font-size-h5: 30.00px;
$font-size-h6: 24.00px;
$font-size-small: 19.20px;
$font-size-tiny: 15.36px;
$font-size-default: $global-font-size;
$font-size-large: 58.59px;
$font-size-huge: 73.24px;

/**
 * Wrapper
 */
$wrapper-width: 1280px;
$wrapper-gutter: $global-spacing-unit;