/*
 *  Elements: Headings
 *  ---
 *  We have all of our heading font sizes defined here. Passing these pixel
 *  values into our `font-size()` mixin will generate a rem-based
 *  `font-size` with a pixel fallback, as well as generating a `line-height` that
 *  will sit on our baseline grid.
 */

h1 {
    font-family: $secondary-font-family;
    font-weight: 300;
    @include font-size($font-size-h1);
    &:only-child {
        margin-bottom: 0;
    }
}

h2 {
    font-family: $secondary-font-family;
    font-weight: 300;
    @include font-size($font-size-h2, 1.3);
    &:only-child {
        margin-bottom: 0;
    }
}

h3 {
    font-family: $secondary-font-family;
    font-weight: 300;
    @include font-size($font-size-h3, 1.5);
    &:only-child {
        margin-bottom: 0;
    }
}

h4 {
    font-family: $secondary-font-family;
    font-weight: 300;
    @include font-size($font-size-h4, 1.5);
    &:only-child {
        margin-bottom: 0;
    }
}

h5 {
    font-family: $secondary-font-family;
    font-weight: 300;
    @include font-size($font-size-h5, 1.5);
    &:only-child {
        margin-bottom: 0;
    }
}

h6 {
    font-family: $secondary-font-family;
    font-weight: 300;
    @include font-size($font-size-h6, 1.5);
    &:only-child {
        margin-bottom: 0;
    }
}
