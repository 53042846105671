/*
 *  Utilities: Font sizes
 *  ---
 *  A set of font sizing helper classes
 */

.u-font-tiny{
  @include font-size($font-size-tiny);
}

.u-font-small{
  @include font-size($font-size-small);
}

.u-font-default{
  @include font-size($font-size-default);
}

.u-font-large{
  @include font-size($font-size-large);
}

.u-font-huge{
  @include font-size($font-size-huge);
}
