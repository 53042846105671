/**
 * Object: Title
 *
 * Reusable title objects
 */

.o-title {

    letter-spacing: -0.03em;

    &.--size {

        &-large {
            @include font-size($font-size-h2);
            font-weight: 500;
            text-align: center;
            width: 100%;

            margin: 0.3em 0;

            @include media('>small') {
                @include font-size($font-size-h1);
            }

        }

        &-huge {

            @include font-size($font-size-h2);
            text-align: center;
            font-weight: 500;
            width: 100%;

            margin: 0.3em 0;

            @include media('>small') {
                @include font-size(77px);
            }

        }

    }

}
