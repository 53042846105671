/**
 * Object: Text Animations
 *
 * Reusable text animations
 */

.o-text-anim {

    position: absolute;
    width: 100%;
    top: 0;

    margin: 0;

    &.--type {

        &-blink {

            display: none;

            &:last-child {
                display: inherit;
            }

        }

        &-cascade {

            opacity: 0;
            // transform: translateY(16px);
            animation: 0.15s cascade $custom-ease;

            @for $i from 0 through 48 {
                &:nth-child(#{$i}) {
                    // animation-delay: $i * (1s / 16) * $i*0.0625;
                    animation-delay: 0.15s * $i;
                }
            }

            &:last-child {
                animation-fill-mode: forwards;
            }

            @keyframes cascade {
                100% {
                    opacity: 1;
                    // transform: translateY(0);
                }
            }

        }

    }

}
