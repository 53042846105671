/**
 * Component: Slide
 *
 * Slide components used to create sections
 */

.c-slide {

    height: 100%;

    @include media('>small') {
        min-height: 95vh;
    }

    &.--size {

        &-full {
            min-height: 100vh;
        }

        &-three-quarters {
            min-height: 75vh;
        }

        &-half {
            min-height: 50vh;
        }

    }

    &.--color {

        &-primary {
            --slide-text-color: #{$color-primary};
            background-color: $color-primary;
            color: white;
        }

        &-secondary {
            --slide-text-color: #{$color-secondary};
            background-color: $color-secondary;
            color: white;
        }

        &-tertiary {
            --slide-text-color: #{$color-tertiary};
            background-color: $color-tertiary;
            color: white;
        }

    }

    &__wrapper {

        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: column;

        margin: 0 auto;
        max-width: $wrapper-width;

        min-height: inherit;

        padding: $global-spacing-unit-large $global-spacing-unit;

    }

    &__content {

        @include font-size($font-size-h3, 1.3);
        font-weight: 300;
        text-align: center;
        margin: 1em;
        width: 100%;

        @include media('>small') {
            @include font-size($font-size-h2, 1.4);
        }

        a {
            color: white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }

        &.--type {

            &-desc {

                a {
                    color: var(--slide-text-color);
                    background-color: white;
                    padding: 0 6px;
                }

            }

        }

    }

    &__intro {
        margin: 0 0 $global-spacing-unit 0;

        @include media('>small') {
            margin: 0 auto $global-spacing-unit-large auto;
        }

    }

    &__tagline {
        position: relative;
    }

}
