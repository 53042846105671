/*
 *  Layers
 *  ---
 *  Loops trough all z-index levels and generates classes
 */

// Loop through the z-indexes map and generate classes (e.g: u-index-alpha)
@each $index,
$value in $z-indexes {
    .u-index-#{$index} {
        z-index: $value;
    }
}
