/**
 * Component: Media
 *
 * Media components
 */

.c-media {

    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        max-width: $wrapper-width;
        margin: 0 auto;
    }

}
