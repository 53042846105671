/**
 * Component: Head Animations
 *
 * Head animations
 */

.c-head {

    width: inherit;
    height: inherit;
    background-size: auto 250px;
    position: absolute;

    will-change: background-image;
    transform: translate3d(0, 0, 0);

    @include media('>small') {
        background-size: auto 375px;
    }

    &.--hidden {
        opacity: 0;
        animation: none !important;
    }

    &.--anim {

        &-rest {
            --image-size: -3000px;
            // background-image: url('../img/head_rest_500.jpg');
            background-image: url('https://ik.imagekit.io/7fwolukn2n/head_rest_500_t7wbpQMZA_0.jpg');
            animation: moveX 0.6s steps(6) infinite; // 12FPS = 12/12 = 1s

            @include media('>small') {
                --image-size: -4500px;
            }

        }

        &-move-one {
            --image-size: -15500px;
            // background-image: url('../img/head_move_1_500.jpg');
            background-image: url('https://ik.imagekit.io/7fwolukn2n/head_move_1_500_1Or34CFAL.jpg');
            animation: moveX 3.1s steps(31) infinite; // 12FPS = 62/12 = 5.1

            @include media('>small') {
                --image-size: -23250px;
            }
        }

    }

    @keyframes moveX {
        from {
            background-position-x: 0px;
        }

        to {
            background-position-x: var(--image-size);
        }
    }

    &__wrapper {
        position: relative;
        width: 250px;
        height: 250px;
        margin: -50px auto 0 auto;

        @include media('>small') {
            width: 375px;
            height: 375px;
        }

    }

}
