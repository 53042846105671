/*
 *  Elements: page
 *  ---
 *  Simple page-level setup.
 */

body {
    font-family: $primary-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ($global-font-size / 16px) * 1em;
    color: palette(color-base, base);

    overflow-x: hidden;
    overflow-y: auto;

    transition: 0.32s opacity 0.32s $custom-smooth;

}

html {

    background-color: $color-primary;

    &:not(.wf-active) {
        font-family: $fallback-font-family;

        body {
            opacity: 0;
        }

    }

}
