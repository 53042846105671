/*
 *  Settings: Colors
 *  ---
 *  Every color that is being used should be defined here.
 *  Based on : http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
 *
 *  Naming scheme : x-dark, dark, mid-dark, base (default), mid-light, light, x-light
 */

$color-base: #000000;

$color-primary: #f15c5b;
$color-secondary: #648494;
$color-tertiary: #f8af3d;

// Palettes
$palettes: (
    color-base: (
        x-light : hsl(hue($color-base), 0, 80),
        light : hsl(hue($color-base), 0, 60),
        base : $color-base,
        dark : hsl(hue($color-base), 0, 0)
    )
);
