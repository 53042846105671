/**
 * Component: List
 *
 * List components used to create layouts
 */

.c-list {

    &.--reset {

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            list-style: none;
            margin: 0.3rem;
        }

        li:empty {
            margin-bottom: 1em;
        }

    }

    &.--type {

        &-split {
            column-count: 1;
            column-gap: $wrapper-gutter*3;
            column-rule: 2px solid white;

            @include media('>small') {
                column-count: 2;
            }

        }

    }

}
